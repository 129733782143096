import Vue from 'vue';

//RECURSOS
import MasterPage from "./shared/MasterPage.vue"

import ModuloCard from "./shared/ModuloCard.vue";
import TextSearch from "./shared/TextSearch.vue";
import AlterarSenha from './shared/AlterarSenha.vue';
import Avatar from "./shared/Avatar.vue";
import BreadCrumb from "./shared/BreadCrumb.vue";
import ListaHeader from './shared/ListaHeader.vue';
import RelatorioPage from './shared/RelatorioPage.vue';
import StatusChip from "./shared/StatusChip.vue";

Vue.component("master-page", MasterPage);

Vue.component("modulo-card", ModuloCard);
Vue.component("text-search", TextSearch);
Vue.component("alterar-senha", AlterarSenha);
Vue.component("avatar-component", Avatar);
Vue.component("breadCrumb-component", BreadCrumb);
Vue.component("lista-header", ListaHeader);
Vue.component("relatorio-page", RelatorioPage);
Vue.component("status-chip", StatusChip);

// CADASTRO GERAL
import CadastroBoletimDiarioApropriacao from "./geral/boletimDiarioApropriacao/Cadastro.vue";
import CadastroBoletimDiarioApropriacaoItem from "./geral/boletimDiarioApropriacao/CadastroItem.vue";
import CadastroContrato from "./geral/contrato/Cadastro.vue";
import CadastroContratoItem from "./geral/contrato/CadastroItem.vue";
import CadastroMedicao from "./geral/medicao/Cadastro.vue";
import SelecionaMedicao from "./geral/medicao/Seleciona.vue";
import CadastroPassagemNivel from "./geral/passagemNivel/Cadastro.vue";
import CadastroPessoa from "./geral/pessoa/Cadastro.vue";
import CadastroServico from "./geral/servico/Cadastro.vue";
import CadastroTipoDemanda from "./geral/tipoDemanda/Cadastro.vue";
import CadastroUnidadeMedida from "./geral/unidadeMedida/Cadastro.vue";

Vue.component("cadastro-boletim-diario-apropriacao", CadastroBoletimDiarioApropriacao);
Vue.component("cadastro-boletim-diario-apropriacao-item", CadastroBoletimDiarioApropriacaoItem);
Vue.component("cadastro-contrato", CadastroContrato);
Vue.component("cadastro-contrato-item", CadastroContratoItem);
Vue.component("cadastro-medicao", CadastroMedicao);
Vue.component("seleciona-medicao", SelecionaMedicao);
Vue.component("cadastro-passagem-nivel", CadastroPassagemNivel);
Vue.component("cadastro-pessoa", CadastroPessoa);
Vue.component("cadastro-servico", CadastroServico);
Vue.component("cadastro-tipo-demanda", CadastroTipoDemanda);
Vue.component("cadastro-unidade-medida", CadastroUnidadeMedida);

//