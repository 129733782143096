import { Service } from "../shared/Service";
import { http } from "@/core/ApiConfig";

export class MedicaoService extends Service {
    
    constructor() {
        super('v1/medicao');
    }

    private GetFiltro(parametros: string, filter: any){
        if (filter) {
            let paramTemp = '';

            if(filter.mesSelecionado){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `competencia ge ${filter.mesSelecionado}`;
            }

            if (paramTemp) {

            if(parametros){
                parametros += '&';
            }
            else {
                parametros += '?';
            }

            parametros += `$filter=${paramTemp}`;
            }
        }
        
        return parametros;  
    }

    public ListarComFiltro(page:number, pageSize:number, sortBy:any[], desc:any[], search:any, columns:any[], filter:any, include){
        let parametros = this.GetParamentroPaginacaoOrdenacao(page,pageSize, sortBy,desc,search, columns);
        parametros = this.GetFiltro(parametros, filter);
        return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
    }

}