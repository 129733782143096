import { MedicaoItem } from ".";

export class Medicao implements Shared.IEntity {

    id: number = 0;
    empresaId!: string;
    numero: number = 0;
    competencia: number = 1;
    ano: number = 2023;
    dataHoraCriacao!: string;
    observacao: string = "";

    itens: MedicaoItem[] = [];

    constructor(model?: Medicao){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.numero = model.numero;
        this.competencia = model.competencia;
        this.ano = model.ano;
        this.dataHoraCriacao = model.dataHoraCriacao.toDateTimeYYYYMMDD();
        this.observacao = model.observacao;

        this.itens = model.itens;
    }
}