import { BoletimDiarioApropriacao, Medicao } from ".";

export class MedicaoItem implements Shared.IEntity {

    id: number = 0;
    medicaoId: number = 0;
    medicao!: Medicao;
    itemId: number = 0;
    item!: BoletimDiarioApropriacao;

    constructor(model?: MedicaoItem){

        if(!model)
            return;

        this.id = model.id;
        this.medicaoId = model.medicaoId;
        this.medicao = model.medicao;
        this.itemId = model.itemId;
        this.item = model.item;
    }
}