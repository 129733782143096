import { ContratoItem } from "./";

export class BoletimDiarioApropriacaoItem {

    id: number = 0;
    itemId: number = 0;
    item!: ContratoItem;
    quantidade: number = 0;
    valor: number = 0;

    constructor(model?: BoletimDiarioApropriacaoItem){

        if(!model)
            return;

        this.id = model.id;
        this.itemId = model.itemId;
        this.item = model.item;
        this.quantidade = model.quantidade;
        this.valor = model.valor;
    }
}