import axios from "axios";

export const httpHeader = {
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "include": ""
  }
};

export const http = axios.create({
  baseURL: process.env.VUE_APP_AUTHAPI,
  headers: httpHeader.headers
});

http.interceptors.request.use(config => {
  if (localStorage.sessionApp) {
    let sessionApp = JSON.parse(localStorage.sessionApp);
    config.headers!.Authorization = "Bearer " + sessionApp.token;
  }
  return config;
});
