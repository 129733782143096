import { Endereco } from './Endereco';

export class PessoaBase{
    
    nome: string = '';
    dataCriacao: string = '';
    isJuridica: boolean = true;
    fisica?: PessoaFisica;
    juridica?: PessoaJuridica;
    endereco: Endereco = new Endereco();
    telefone1: string = '';
    telefone2: string = '';
    email: string = '';
    site: string = '';
    foto: any = null;
    observacao: string = '';
    ativo: boolean = true;

    constructor(model?: PessoaBase){
        
        if(!model)
            return;

        this.nome = model.nome;
        this.dataCriacao = model.dataCriacao;
        this.isJuridica = model.isJuridica;
        this.fisica = model.fisica ? new PessoaFisica(model.fisica) : undefined;
        this.juridica = model.juridica ? new PessoaJuridica(model.juridica) : undefined;
        this.endereco = model.endereco;
        this.telefone1 = model.telefone1;
        this.telefone2 = model.telefone2;
        this.email = model.email;
        this.site = model.site;
        this.foto = model.foto;
        this.observacao = model.observacao;
        this.ativo = model.ativo;
    }
}

export class PessoaJuridica{
    
    cnpj: string = '';
    nomeFantasia: string = '';
    inscricaoEstadual: string = '';
    inscricaoMunicipal: string = '';

    constructor(model?: PessoaJuridica){
        
        if(!model)
            return;

        this.cnpj = model.cnpj;
        this.nomeFantasia = model.nomeFantasia;
        this.inscricaoEstadual = model.inscricaoEstadual;
        this.inscricaoMunicipal = model.inscricaoMunicipal;
    }
}

export class PessoaFisica{
    
    cpf: string = '';
    identidade: string = '';

    constructor(model?: PessoaFisica){
        
        if(!model)
            return;
            
        this.cpf = model.cpf;
        this.identidade = model.identidade;
    }
}