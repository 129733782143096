export class UnidadeMedida implements Shared.IEntity {

    id: number = 0;
    nome: string = "";
    sigla: string = "";
    ativo: boolean = true;

    constructor(model?: UnidadeMedida){
        if(!model)
            return;

        this.id = model.id;
        this.nome = model.nome;
        this.sigla = model.sigla;
        this.ativo = model.ativo;
    }
}