import { Contrato, Pessoa, Servico, UnidadeMedida } from "./";

export class ContratoItem {

    id: number = 0;
    contratoId: number = 0;
    contrato!: Contrato;
    servicoId: number = 0;
    servico!: Servico;
    unidadeMedidaId: number = 0;
    unidadeMedida!: UnidadeMedida;
    valor: number = 0;

    constructor(model?: ContratoItem){
        if(!model)
            return;

        this.id = model.id;
        this.contratoId = model.contratoId;
        this.contrato = model.contrato;
        this.servicoId = model.servicoId;
        this.servico = model.servico;
        this.unidadeMedidaId = model.unidadeMedidaId;
        this.unidadeMedida = model.unidadeMedida;
        this.valor = model.valor;
    }
}