
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase } from '@/core/models/shared';
import { AlertSimpleErr } from '@/core/services/shared/AlertService';
import { ContratoItem, Servico, UnidadeMedida } from '@/core/models/geral';
import { ServicoService, UnidadeMedidaService } from '@/core/services/geral';

@Component
export default class CadastroContratoItem extends CrudBase {    
  @Prop() item!: ContratoItem;
  @Prop() value!: string;

  $refs!: {
    form: HTMLFormElement
  }

  servicoService: ServicoService = new ServicoService();
  servicos: Servico[] = [];

  unidadeMedidaService: UnidadeMedidaService = new UnidadeMedidaService();
  unidadeMedidas: UnidadeMedida[] = [];  

  @Watch('value')
  Value(){
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (this.dialog) {
      this.Carregar();
    }
    else{
      this.$emit("fechou");
    }
    if(this.$refs.form)
      this.$refs.form.resetValidation();
  }

  @Watch("item")
  WatchItem(){
    if(this.item.id > 0){
      
    }
  }  

  @Watch("item.servicoId")
  WatchServico(){
    if(this.item.servicoId > 0 && this.item.id == 0){
      this.item.servico = this.servicos.find(x => x.id == this.item.servicoId)!;
      this.item.unidadeMedidaId = this.item.servico.unidadeMedidaId;
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  Carregar(){
    this.servicoService.ListarTudo().then(
      res => this.servicos = res.data.items,
      err => AlertSimpleErr("Aviso!", err)
    )
    this.unidadeMedidaService.ListarTudo().then(
      res => this.unidadeMedidas = res.data.items,
      err => AlertSimpleErr("Aviso!", err)
    )
  }

  Salvar(){
    if(this.$refs.form.validate()){
      this.item.servico = this.servicos.find(x => x.id == this.item.servicoId)!;
      this.item.unidadeMedida = this.unidadeMedidas.find(x => x.id == this.item.unidadeMedidaId)!;
      this.Salvou();
    }
  }

  Salvou(){
    this.$emit("salvou");
    this.Close();
  }

  Close(){
    this.$emit("fechou");
    this.dialog = false;
  }
}
