
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { BoletimDiarioApropriacao, Medicao, MedicaoItem } from '@/core/models/geral';
import { BoletimDiarioApropriacaoService, MedicaoService } from '@/core/services/geral';

@Component
export default class CadastroContrato extends CrudBase{
    @Prop() item!: Medicao;
    @Prop() value!: string;

    itemOriginal!: Medicao;
    service: MedicaoService = new MedicaoService();
    $refs!: {
        form: HTMLFormElement
    }

    valorTotal: number = 0;
    valor: any;
    quantidade: number = 0;

    dialogItensBDA: boolean = false;
    editaItens: boolean = false;
    medicaoItem: MedicaoItem = new MedicaoItem();

    boletimSelecionado: BoletimDiarioApropriacao[] = [];
    boletimDiarioApropriacaoService: BoletimDiarioApropriacaoService = new BoletimDiarioApropriacaoService();

    headerItens: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action'},
        { text: '#', value: 'item.numero' },
        { text: 'Contrato', value: 'item.contrato.codigoCliente' },
        { text: 'Data', value: 'item.data' },
        { text: 'Passagem Nível', value: 'item.passagemNivel.nome' },
    ];

    meses: any[] = [
        { valor: 1, texto: "Janeiro" },
        { valor: 2, texto: "Fevereiro" },
        { valor: 3, texto: "Março" },
        { valor: 4, texto: "Abril" },
        { valor: 5, texto: "Maio" },
        { valor: 6, texto: "Junho" },
        { valor: 7, texto: "Julho" },
        { valor: 8, texto: "Agosto" },
        { valor: 9, texto: "Setembro" },
        { valor: 10, texto: "Outubro" },
        { valor: 11, texto: "Novembro" },
        { valor: 12, texto: "Dezembro" }
    ]
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar(){
        
    }

    BoletinsSelecionados() {
        this.boletimSelecionado.forEach(x => {
            this.boletimDiarioApropriacaoService.ObterPorId(x.id, "Contrato.Cliente, PassagemNivel").then(
                res => {
                    let boletim = new BoletimDiarioApropriacao(res.data);
                    let medicaoItem = new MedicaoItem();
                    medicaoItem.medicaoId = this.item.id;
                    medicaoItem.itemId = boletim.id;
                    medicaoItem.item = boletim;
                    this.item.itens.push(medicaoItem);
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        });
    }

    AbrirDialogBDAItem(item?: MedicaoItem){
        if(item) {
            this.editaItens = true;
            this.medicaoItem = item;
        }
        else {
            this.editaItens = false;
            this.medicaoItem = new MedicaoItem();
        }
        this.dialogItensBDA = true;
    }

    ExcluirItens(item: MedicaoItem){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.itens.indexOf(item);
                context.item.itens.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }   

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }
    
    Close(){
        this.dialog = false;
    }
}
