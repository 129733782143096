
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class TextSearch extends Vue {
    @Prop({required: true}) private label!: string;
    @Prop({required: true}) private fontColor!: string;
    @Prop({required: true}) private backgroundColor!: string;    
}

