
import { PageBase } from '@/core/models/shared';
import { UsuarioService } from '@/core/services/auth';
import { AlertSimpleErr } from '@/core/services/shared/AlertService';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';


@Component
export default class MasterPage extends PageBase{
  @Prop() private icone!: string;
  @Prop() private titulo!: string;  

  drawer: boolean = false;
  dialogAlterarSenha: boolean = false;

  modulos: any[] = []
  menuOpts: any[] = [];

  mounted() {
    this.$vuetify.theme.dark = this.app.temaEscuro;
    this.CarregarModulos();
    this.CarregarMenu();
  }

  Home(){
    location.href= `${this.app.sistema.autenticacaoUrl}/home`;
  }

  CarregarModulos(){
    this.modulos = [];
    this.app.sistema.modulos.forEach(modulo => {
      this.modulos.push({
        nome: modulo.nome,
        icone: `mdi-${modulo.icone}`,
        termo: modulo.termo
      })
    });
  }

  CarregarMenu(){
    this.menuOpts = [];
    this.menuOpts = this.app.sistema.modulos.filter(x => x.termo == "gestaoboletimdiarioapropriacao")[0].menuParents;
  }

  TemaEscuro(){
    const usuarioService = new UsuarioService();
      usuarioService.MudarTema().then(
        res => super.MudarTema(res.data),
        err => AlertSimpleErr("Aviso!", err)
      );
  }

  Logout() {
    localStorage.clear();
    this.RetornaAuth();
  }

}
