import { BoletimDiarioApropriacaoEquipamento, BoletimDiarioApropriacaoEquipe, BoletimDiarioApropriacaoItem, BoletimDiarioApropriacaoPerda, Contrato, Municipio, PassagemNivel, TipoDemanda } from "./";

export class BoletimDiarioApropriacao implements Shared.IEntity {

    id: number = 0;
    numero: number = 0;
    data: string = new Date().toYYYYMMDD();
    contratoId: number = 0;
    contrato!: Contrato;
    passagemNivelId: number = 0;
    passagemNivel!: PassagemNivel;
    municipioId: number= 0;
    municipio!: Municipio;
    tipoDemandaId: number = 0;
    tipoDemanda!: TipoDemanda;
    centroCusto: string = "";
    observacao: string = "";

    itens: BoletimDiarioApropriacaoItem[] = [];
    perdas: BoletimDiarioApropriacaoPerda[] = [];
    equipes: BoletimDiarioApropriacaoEquipe[] = [];
    equipamentos: BoletimDiarioApropriacaoEquipamento[] = [];

    constructor(model?: BoletimDiarioApropriacao){

        if(!model)
            return;

        this.id = model.id;
        this.numero = model.numero;
        this.data = model.data.toDateDDMMYYYY();
        this.contratoId = model.contratoId;
        this.contrato = model.contrato;
        this.passagemNivelId = model.passagemNivelId;
        this.passagemNivel = model.passagemNivel;
        this.municipioId = model.municipioId;
        this.municipio = model.municipio;
        this.tipoDemandaId = model.tipoDemandaId;
        this.tipoDemanda = model.tipoDemanda;
        this.centroCusto = model.centroCusto;
        this.observacao = model.observacao;

        this.itens = model.itens;
        this.perdas = model.perdas;
        this.equipes = model.equipes;
        this.equipamentos = model.equipamentos;
    }
}