
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase, Estado, Municipio } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { BoletimDiarioApropriacao, BoletimDiarioApropriacaoEquipamento, BoletimDiarioApropriacaoEquipe, BoletimDiarioApropriacaoItem, BoletimDiarioApropriacaoPerda, Contrato, ContratoEquipamento, ContratoEquipe, ContratoPerda, PassagemNivel, Pessoa, TipoDemanda } from '@/core/models/geral';
import { BoletimDiarioApropriacaoService, ContratoService, PassagemNivelService, TipoDemandaService } from '@/core/services/geral';
import { EnderecoService } from '@/core/services/finder';

@Component
export default class CadastroBoletimDiarioApropriacao extends CrudBase{
    @Prop() item!: BoletimDiarioApropriacao;
    @Prop() value!: string;

    itemOriginal!: BoletimDiarioApropriacao;
    service: BoletimDiarioApropriacaoService = new BoletimDiarioApropriacaoService();
    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement
    }

    passagemNivelService: PassagemNivelService = new PassagemNivelService();
    passagensNiveis: PassagemNivel[] = [];

    tipoDeDemandaService: TipoDemandaService = new TipoDemandaService();
    tiposDedemandas: TipoDemanda[] = [];

    onSearchContrato: any = null;
    isContratoLoading: boolean = false;
    contratoService: ContratoService = new ContratoService();
    contratos: Contrato[] = [];
    contrato: Contrato = new Contrato();    

    boletimDiarioApropriacaoItem: BoletimDiarioApropriacaoItem = new BoletimDiarioApropriacaoItem();
    dialogCadastroBDAItem: boolean = false;
    editaItens: boolean = false;

    enderecoService: EnderecoService = new EnderecoService();
    estadoId: number = 0;
    estados: Estado[] = [];
    municipios: Municipio[] = [];

    headerItens: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action'},
        { text: 'Item', value: 'item.servico.servicoUnidadeMedida' },
        { text: 'Quantidade', value: 'quantidade'},
    ];

    headerBoletinDiarioPerda: any[] = [
        { text: 'Check', value: 'check' },
        { text: 'Descrição', value: 'contratoPerda.descricao' },
        { text: 'Justificativa', value: 'justificativa' },
    ];

    headerBoletinDiarioEquipe: any[] = [
        { text: 'Real', value: 'real' },
        { text: 'Função', value: 'contratoEquipe.funcao' },
        { text: 'Função Observação', value: 'contratoEquipe.funcaoObservacao' },
        { text: 'Planejado', value: 'contratoEquipe.planejado' },
        { text: 'Hora Início', value: 'horaInicio' },
        { text: 'Hora Fim', value: 'horaFim' },
        { text: 'Observação', value: 'observacao' },
    ];

    headerBoletinDiarioEquipamentos: any[] = [
        { text: 'Real', value: 'real' },
        { text: 'Função', value: 'contratoEquipamento.funcao' },
        { text: 'Função Observação', value: 'contratoEquipamento.funcaoObservacao' },
        { text: 'Planejado', value: 'contratoEquipamento.planejado' },
        { text: 'KM Início', value: 'kmInicio' },
        { text: 'KM Fim', value: 'kmFim' },
        { text: 'Observação', value: 'observacao' },
    ];
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
            this.estadoId = this.item.municipio.estadoId;
            this.contratos.push(this.item.contrato);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch('item.contratoId')
    WatchContrato() {
        if(this.item.contratoId > 0) {
            this.contratoService.ObterPorId(this.item.contratoId, "Itens.Servico, Perdas, Equipamentos, Equipes").then(
                res => {
                    this.contrato = new Contrato(res.data);
                    this.AdicionarItensContrato();
                },
                err => AlertSimpleErr("Aviso", err)
            )
        }
    }

    @Watch('estadoId')
    WatchEstado() {
        if (this.estadoId > 0) {
            this.enderecoService.ObterMunicipios(this.estadoId).then(
                res => this.municipios = res.data.items,
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    @Watch("onSearchContrato")
    Watch(val: any){
        if(this.item.contratoId || this.isContratoLoading) return;
        if (!val) return;
        this.isContratoLoading = true;
        this.contratoService.AutoComplete(val).then(
            res => {
                this.contratos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isContratoLoading = false;
        })
    }

    AdicionarItensContrato(){
        if(this.item.id == 0){
            this.contrato.perdas.forEach(perda => {
                let bdaPerda = new BoletimDiarioApropriacaoPerda();
                bdaPerda.contratoPerdaId = perda.id;
                bdaPerda.contratoPerda = perda;
                this.item.perdas.push(bdaPerda);
            });

            this.contrato.equipes.forEach(equipe => {
                let bdaEquipe = new BoletimDiarioApropriacaoEquipe();
                bdaEquipe.contratoEquipeId = equipe.id;
                bdaEquipe.contratoEquipe = equipe;
                this.item.equipes.push(bdaEquipe);
            });

            this.contrato.equipamentos.forEach(equipamento => {
                let bdaEquipamento = new BoletimDiarioApropriacaoEquipamento();
                bdaEquipamento.contratoEquipamentoId = equipamento.id;
                bdaEquipamento.contratoEquipamento = equipamento;
                this.item.equipamentos.push(bdaEquipamento);
            });
        }
    }

    Carregar() {
         this.passagemNivelService.ListarTudo().then(
            res => this.passagensNiveis = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        )
        this.tipoDeDemandaService.ListarTudo().then(
            res => this.tiposDedemandas = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        )        
        this.enderecoService.ObterEstados().then(
            res => {
                this.estados = res.data.items
                if (this.item.id == 0) {
                    this.estadoId = 33;
                }
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    //ITENS
    AbrirDialogBDAItem(item?: BoletimDiarioApropriacaoItem){
        if(item) {
            this.editaItens = true;
            this.boletimDiarioApropriacaoItem = item;
        }
        else {
            this.editaItens = false;
            this.boletimDiarioApropriacaoItem = new BoletimDiarioApropriacaoItem();
        }
        this.dialogCadastroBDAItem = true;
    }

    SalvarBDAItem(){
        if(!this.editaItens)
            this.item.itens.push(this.boletimDiarioApropriacaoItem);
        this.boletimDiarioApropriacaoItem = new BoletimDiarioApropriacaoItem();
    }

    ExcluirItens(item: BoletimDiarioApropriacaoItem){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.itens.indexOf(item);
                context.item.itens.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }
    
    Close(){
        this.dialog = false;
    }
}
