import { ContratoPerda } from "./";

export class BoletimDiarioApropriacaoPerda {

    id: number = 0;
    contratoPerdaId: number = 0;
    contratoPerda!: ContratoPerda;
    check: boolean = false;
    justificativa: string = "";

    constructor(model?: BoletimDiarioApropriacaoPerda){

        if(!model)
            return;

        this.id = model.id;
        this.contratoPerdaId = model.contratoPerdaId;
        this.contratoPerda = model.contratoPerda;
        this.check = model.check;
        this.justificativa = model.justificativa;
    }
}