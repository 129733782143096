import { http, httpHeader } from "@/core/ApiAuthConfig";


export class AutenticadorService {

    private _nomeControle: string = 'v1/autenticador';
    
    public RetriveSessionApp() {
        return http.get(`${this._nomeControle}/RetrieveSessionApp`, httpHeader);
    }
}