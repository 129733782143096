
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase } from '@/core/models/shared';
import { AlertSimpleErr } from '@/core/services/shared/AlertService';
import { BoletimDiarioApropriacaoItem, Contrato, ContratoItem } from '@/core/models/geral';

@Component
export default class CadastroBoletimDiarioApropriacaoItem extends CrudBase {    
    @Prop() item!: BoletimDiarioApropriacaoItem;
    @Prop() value!: string;
    @Prop() contrato!: Contrato;

    $refs!: {
        form: HTMLFormElement
    }

    contratoItens: ContratoItem[] = [];
    valor: number = 0;

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {            
            this.contratoItens = this.contrato.itens;
        }
        else{
            this.$emit("fechou");
        }
        if(this.$refs.form)
            this.$refs.form.resetValidation();
    }   

    @Watch("item.itemId")
    WatchServico(){
        if(this.item.itemId > 0 && this.item.id == 0){
            this.item.valor = this.contratoItens.find(x => x.id == this.item.itemId)!.valor;
        }
    }

    beforeUpdate(){
        if (!this.dialog){
        this.$emit('fechou');
        }
    }

    mounted() {
        
    }

    Salvar(){
        if(this.$refs.form.validate()){
            this.item.item = this.contratoItens.find(x => x.id == this.item.itemId)!;
            this.Salvou();
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }

    Close(){
        this.$emit("fechou");
        this.dialog = false;
    }
    }
    