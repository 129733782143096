import { UnidadeMedida } from "./";

export class Servico implements Shared.IEntity {

    id: number = 0;
    codigo: string = "";
    nome: string = "";
    descricao: string = "";
    unidadeMedidaId: number = 0;
    unidadeMedida!: UnidadeMedida;

    constructor(model?: Servico){
        if(!model)
            return;

        this.id = model.id;
        this.codigo = model.codigo;
        this.nome = model.nome;
        this.descricao = model.descricao;
        this.unidadeMedidaId = model.unidadeMedidaId;
        this.unidadeMedida = model.unidadeMedida;
    }
}