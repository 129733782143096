import { ContratoEquipamento, ContratoEquipe, ContratoItem, ContratoPerda, Pessoa } from "./";

export class Contrato implements Shared.IEntity {

    id: number = 0;
    numero: number = 0;
    descricao: string = "";
    clienteId: number = 0;
    cliente!: Pessoa;
    ativo: boolean = true;

    itens: ContratoItem[] = [];
    perdas: ContratoPerda[] = [];
    equipamentos: ContratoEquipamento[] = [];
    equipes: ContratoEquipe[] = [];

    constructor(model?: Contrato){

        if(!model)
            return;

        this.id = model.id;
        this.numero = model.numero;
        this.descricao = model.descricao;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
        this.ativo = model.ativo;

        this.itens = model.itens;
        this.perdas = model.perdas;
        this.equipamentos = model.equipamentos;
        this.equipes = model.equipes;
    }
}