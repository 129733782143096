
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { CrudBase, Estado, Municipio } from '@/core/models/shared';
import { EnderecoService, EntidadeLegalService } from '@/core/services/finder'
import { Pessoa, } from '@/core/models/geral';
import { PessoaService } from '@/core/services/geral';
import { PessoaFisica, PessoaJuridica } from '@/core/models/shared/PessoaBase';

@Component
export default class CadastroPessoa extends CrudBase{
    @Prop() item!: Pessoa;
    @Prop() value!: string;

    itemOriginal!: Pessoa;
    service: PessoaService = new PessoaService();
    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement
    }

    fisicaJuridica: any[] = [
        { id: true, nome: "Juridica"},
        { id: false, nome: "Física"}
    ]
    juridicaAux: boolean = false;

    estadoId: number = 0;
    estados: Estado[] = [];
    municipios: Municipio[] = [];
    enderecoService: EnderecoService = new EnderecoService();
    entidadeLegalService: EntidadeLegalService = new EntidadeLegalService();

    logo: any = null;

    produtoHeader: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action'},
        { text: 'Foto', value: 'produto.foto' },
        { text: 'Nome', value: 'produto.nome' },
        { text: 'Codigo', value: 'codigo' },
        { text: 'Ean', value: 'ean' },
        { text: 'Ean Inner', value: 'eanInner' },
        { text: 'Ean Master', value: 'eanMaster' },
        { text: 'Ncm', value: 'ncm' },
    ];

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if(this.dialog){
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);            
            this.estadoId = this.item.endereco.municipio.estadoId;
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch("estadoId")
    onEstadoIdChanged(value: number) {
        this.CarregarMunicipio(value);
    }

    @Watch("item.isJuridica", {deep: true})
    WatchJuridica(){
        this.juridicaAux = this.item.isJuridica;
        if(this.item.isJuridica){
            this.item.juridica = new PessoaJuridica(this.item.juridica);            
            this.item.fisica = undefined;
        }
        else{
            this.item.fisica = new PessoaFisica(this.item.fisica);
            this.item.juridica = undefined;
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar(){
        this.enderecoService.ObterEstados().then(
        res => {
            this.estados = res.data.items;
        },
        err => AlertSimpleErr("Aviso!", err)
      );
    }

    CarregarMunicipio(estadoId: number){
        this.enderecoService.ObterMunicipios(estadoId).then(
            res => {
                this.municipios = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        );
    }

    ObterEndereco(cep: string){
        this.loading = true;
        this.enderecoService.ObterPorCep(cep).then(
            res => {
                const endereco = res.data;
                if (endereco) {
                    this.item.endereco.logradouro = endereco.logradouro;
                    this.item.endereco.bairro = endereco.bairro;
                    this.estadoId = endereco.estadoId;
                    this.item.endereco.municipioId = endereco.municipioId;
                }
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.loading = false;
        });
    }

    ObterEntidadeLegal(cnpj: string){
        this.loading = true;
        this.entidadeLegalService.ObterPorCnpj(cnpj).then(
            res => {
                const entidade = res.data;
                if (entidade) {
                    this.item.nome = entidade.nome;
                    this.item.juridica!.nomeFantasia = entidade.nomeFantasia;
                    this.item.endereco.cep = entidade.endereco.cep;
                    this.item.endereco.logradouro = entidade.endereco.logradouro;
                    this.item.endereco.numero = entidade.endereco.numero;
                    this.item.endereco.complemento = entidade.endereco.complemento;
                    this.item.endereco.bairro = entidade.endereco.bairro;
                    this.estadoId = entidade.endereco.estadoId;
                    this.item.endereco.municipioId = entidade.endereco.municipioId;
                    this.item.email = entidade.email;
                }
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.loading = false;
        });
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Close(){
        this.Limpar();
        this.dialog = false;
    }

    Limpar(){
        this.estadoId = 0;
        this.item.isJuridica = this.juridicaAux;
    }
}
