import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared/Service";

export class UnidadeMedidaService extends Service {
    
    constructor() {
        super('v1/unidadeMedida');
    }

    public AutoComplete(q: string, tipo?: string) {
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
          params: {
            q,
            tipo
          },
          headers: httpHeader.headers
        });
      }
    
}