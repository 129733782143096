
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

import { BoletimDiarioApropriacao } from "@/core/models/geral";
import { BoletimDiarioApropriacaoService } from "@/core/services/geral";
import { AlertSimpleErr } from "@/core/services/shared";

@Component
export default class SelecionarBoletimDiarioApropriacao extends Vue {
  @Prop() value!: boolean;
  @Prop() selected!: BoletimDiarioApropriacao[];
  @Prop() competencia!: number;
  @Prop() ano!: number;

  $refs!: {
    form: HTMLFormElement
  }

  service: BoletimDiarioApropriacaoService = new BoletimDiarioApropriacaoService();
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  options: any = {
    itemsPerPage: 15
  };

  totalLista: number = 0;
  localSelected: BoletimDiarioApropriacao[] = [];
  loading: boolean = false;
  lista: any[] = [];

  headers: any[] = [
    { text: '',value:'actions' ,sortable: false, class: 'action'},
    { text: 'Boletim Diário de Apropriação', value: 'codigoContrato' },
    { text: 'Data da Emissão', value: 'data' },
  ];

  sheet: boolean = false;
  filtroRaw: any = {
    competencia: null,
    ano: null
  }

  @Watch("options", { deep: true })
  Atualizar(isFiltro: boolean = false) {

    if (isFiltro == true) {
      this.options.page = 1;
    }

    let filtro =  `data ge ${new Date(this.ano, this.competencia - 1, 1).toYYYYMMDD()} AND data le ${new Date(this.ano, this.competencia, 0).toYYYYMMDD()}`;

    this.sheet = false;
    this.loading = true;

    const { sortBy, sortDesc, page, itemsPerPage } = this.options;

    this.service.Listar(page, itemsPerPage, sortBy, sortDesc, null, this.headers, filtro, "Contrato.Cliente, PassagemNivel").then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      err => {
        AlertSimpleErr("Aviso!", err)
      }
    ).finally(
      () => (this.loading = false)
    );
  }

  @Watch("value")
  Value() {
    if (this.value){
      this.localSelected = [];
      this.Atualizar();
    }
  }

  mounted() {
    
  }

  Selecionar() {
    this.$emit('update:selected', this.localSelected);

    if (this.localSelected.length > 0){
      this.$emit('selecionou');
    }

    this.Close();
  }

  Close() {
    this.$emit("fechou");
  }
}
