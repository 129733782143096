export class ContratoPerda implements Shared.IEntity {

  id: number = 0;
  descricao: string = "";

  constructor(model?: ContratoPerda){

      if(!model)
          return;

      this.id = model.id;
      this.descricao = model.descricao;
  }
}