import { ContratoEquipe } from "./";

export class BoletimDiarioApropriacaoEquipe {

    id: number = 0;
    contratoEquipeId: number = 0;
    contratoEquipe!: ContratoEquipe;
    real: number = 0;
    horaInicio: string = "";
    horaFim: string = "";
    observacao: string = "";

    constructor(model?: BoletimDiarioApropriacaoEquipe){

        if(!model)
            return;

        this.id = model.id;
        this.contratoEquipeId = model.contratoEquipeId;
        this.contratoEquipe = model.contratoEquipe;
        this.real = model.real;
        this.horaInicio = model.horaInicio;
        this.horaFim = model.horaFim;
        this.observacao = model.observacao;
    }
}