
import { http } from "@/core/ApiAuthConfig";
import { Service } from "../shared/Service";

export class UsuarioService extends Service{
    
    constructor(){
        super('v1/usuario');
    }

    async AlterarSenha(senhaAntiga: string, senhaNova: string){
        const senhas: {senhaAntiga: string, senhaNova: string} = {
            senhaAntiga: senhaAntiga,
            senhaNova: senhaNova
        };        
        return await http.post(`${this.GetNomeControle()}/AlterarSenha`, senhas);
    }

    public MudarTema(){    
        return http.put(`${this.GetNomeControle()}/MudarTema`, null);
    }

}