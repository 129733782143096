export class PassagemNivel implements Shared.IEntity {

    id: number = 0;
    codigo: string = "";
    nome: string = "";
    ativo: boolean = true;

    constructor(model?: PassagemNivel){

        if(!model)
            return;

        this.id = model.id;
        this.codigo = model.codigo;
        this.nome = model.nome;
        this.ativo = model.ativo;
    }
}