
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Contrato, ContratoEquipamento, ContratoEquipe, ContratoItem, ContratoPerda, Pessoa } from '@/core/models/geral';
import { ContratoService, PessoaService } from '@/core/services/geral';

@Component
export default class CadastroContrato extends CrudBase{
    @Prop() item!: Contrato;
    @Prop() value!: string;

    itemOriginal!: Contrato;
    service: ContratoService = new ContratoService();
    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement
    }

    clienteService: PessoaService = new PessoaService();
    clientes: Pessoa[] = [];
    onSearchCliente: any = null;
    isClienteLoading: boolean = false;

    dialogCadastroContratoItem: boolean = false;
    editaItem: boolean = false;
    contratoItem: ContratoItem = new ContratoItem();

    contratoPerdas: ContratoPerda[] = [];
    contratoPerda: ContratoPerda = new ContratoPerda();

    contratoEquipamentos: ContratoEquipamento[] = [];
    contratoEquipamento: ContratoEquipamento = new ContratoEquipamento();

    contratoEquipes: ContratoEquipe[] = [];
    contratoEquipe: ContratoEquipe = new ContratoEquipe();

    headerItens: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action'},
        { text: 'Serviço', value: 'servico.nome' },
        { text: 'Unidade de Medida.', value: 'unidadeMedida.sigla'},
        { text: 'Valor', value: 'valor', sortable: false },
    ];

    headerContratoPerda: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action'},
        { text: 'Descrição', value: 'descricao' },
    ];

    headerContratoEquipamento: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action'},
        { text: 'Função', value: 'funcao' },
        { text: 'Planejado', value: 'planejado' },
        { text: 'Observação', value: 'funcaoObservacao' },
    ];
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            // this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
            this.clientes.push(this.item.cliente);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch('onSearchCliente')
    searchCliente (val: string) {
        if (this.item.clienteId) return;
        if (this.isClienteLoading) return;
        if (!val) return;
        this.isClienteLoading = true
        this.clienteService.AutoComplete(val, "c").then(
            res => {
                this.clientes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isClienteLoading = false
        });
    }   

    AbrirDialogCadastroItem(item?: ContratoItem){
        if(item) {
            this.editaItem = true;
            this.contratoItem = item;
        }
        else {
            this.editaItem = false;
            this.contratoItem = new ContratoItem();
        }
        this.dialogCadastroContratoItem = true;
    }

    SalvarContratoItem(){
        if(!this.editaItem)
            this.item.itens.push(this.contratoItem);
        this.contratoItem = new ContratoItem();
    }

    SalvarContratoPerda() {
        if(!this.editaItem)
            this.item.perdas.push(this.contratoPerda);
        this.contratoPerda = new ContratoPerda();
    }

    SalvarContratoEquipamento() {
        if(!this.editaItem)
            this.item.equipamentos.push(this.contratoEquipamento);
        this.contratoEquipamento = new ContratoEquipamento();
    }

    SalvarContratoEquipe() {
        if(!this.editaItem)
            this.item.equipes.push(this.contratoEquipe);
        this.contratoEquipe = new ContratoEquipe();
    }

    ExcluirItens(item: ContratoItem){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.itens.indexOf(item);
                context.item.itens.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    ExcluirContratoPerda(item: ContratoPerda) {
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.perdas.indexOf(item);
                context.item.perdas.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    ExcluirContratoEquipamento(item: ContratoEquipamento) {
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.equipamentos.indexOf(item);
                context.item.equipamentos.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    ExcluirContratoEquipe(item: ContratoEquipe) {
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.equipes.indexOf(item);
                context.item.equipes.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }
    
    Close(){
        this.dialog = false;
    }
}
