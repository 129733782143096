import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/', redirect: '/home' },
  { path: '/auth', name: 'Auth', component: () => import('../views/shared/Auth.vue') },

  { path: '/home', name: 'Home', component: () => import('../views/shared/Home.vue') },

  { path: '/geral/boletimDiarioApropriacao', name: 'BoletimDiarioApropriacao', component: () => import('../views/geral/boletimDiarioApropriacao/Lista.vue') },
  { path: '/geral/boletimDiarioApropriacao/detalhar/:id', name: 'DetalharBoletimDiarioApropriacao', component: () => import('../views/geral/boletimDiarioApropriacao/Detalhar.vue') },
  { path: '/geral/boletimDiarioApropriacao/detalhar2/:id', name: 'DetalharBoletimDiarioApropriacao2', component: () => import('../views/geral/boletimDiarioApropriacao/Detalhar2.vue') },

  { path: '/geral/contrato', name: 'Contrato', component: () => import('../views/geral/contrato/Lista.vue') },
  
  { path: '/geral/medicao', name: 'Medicao', component: () => import('../views/geral/medicao/Lista.vue') },
  { path: '/geral/medicao/detalhar/:id', name: 'DetalharMedicao', component: () => import('../views/geral/medicao/Detalhar.vue') },

  { path: '/geral/passagemNivel', name: 'PassagemNivel', component: () => import('../views/geral/passagemNivel/Lista.vue') },
  { path: '/geral/pessoa', name: 'Pessoa', component: () => import('../views/geral/pessoa/Lista.vue') },
  { path: '/geral/servico', name: 'Servico', component: () => import('../views/geral/servico/Lista.vue') },
  { path: '/geral/tipoDemanda', name: 'TipoDemanda', component: () => import('../views/geral/tipoDemanda/Lista.vue') },
  { path: '/geral/unidadeMedida', name: 'UnidadeMedida', component: () => import('../views/geral/unidadeMedida/Lista.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
