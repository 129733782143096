import { ContratoEquipamento } from "./ContratoEquipamento";

export class BoletimDiarioApropriacaoEquipamento {

    id: number = 0;
    contratoEquipamentoId: number = 0;
    contratoEquipamento!: ContratoEquipamento;
    real: number = 0;
    kmInicio: number = 0;
    kmFim: number = 0;
    observacao: string = "";

    constructor(model?: BoletimDiarioApropriacaoEquipamento){

        if(!model)
            return;

        this.id = model.id;
        this.contratoEquipamentoId = model.contratoEquipamentoId;
        this.contratoEquipamento = model.contratoEquipamento;
        this.real = model.real;
        this.kmInicio = model.kmInicio;
        this.kmFim = model.kmFim;
        this.observacao = model.observacao;
    }
}