import Swal from 'sweetalert2'

async function AlertSimpleNotification(titulo: string, icone: any) {
    Swal.fire({
        toast: true,
        icon: icone,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        title: titulo
    });
}

async function AlertSimple(titulo: string, mensagem: any, icone: any){        
    const alert = await Swal.fire({
        icon: icone,
        title: titulo,
        text: mensagem
    });
}

function AlertSimpleRes(titulo: string, res: any){
    if(res.status == 200){
        const alert = Swal.fire({
            icon: "success",
            title: titulo,
            text: res.data
        });
    }
    else if(res.status == 201){
        const alert = Swal.fire({
            icon: "success",
            title: titulo,
            text: "Criado com sucesso!"
        });
    }
    else{
        const alert = Swal.fire({
            icon: "warning",
            title: titulo,
            text: res.data
        });
    }
}

function AlertSimpleErr(titulo: string, err: any){
    if(!err.response){
        const alert = Swal.fire({
            icon: "error",
            title: titulo,
            text: "Não foi possível acessar a API"
        });
    }
    else{
        if(err.response.status == 400){    
            if(err.response.data.errors){
                const errors: any[] = [];
                for(var i = 0; i < Object.values(err.response.data.errors).length; i ++){
                    var response = {
                        key: Object.keys(err.response.data.errors)[i],
                        value: Object.values(err.response.data.errors)[i]
                    }
                    errors.push(response);
                }
                var mensagem = "<div>";
                errors.forEach(error => {
                    mensagem += `${error.key}: ${error.value} <br/>`;
                });
                const alert = Swal.fire({
                    icon: "warning",
                    title: titulo,
                    html: mensagem + "<div/>"
                });
            }
            else{
                const alert = Swal.fire({
                    icon: "warning",
                    title: titulo,
                    text: err.response.data.title ? err.response.data.title : err.response.data
                });
            }            
        }
        else if(err.response.status == 403){
            const alert = Swal.fire({
                icon: "warning",
                title: titulo,
                text: "Você não tem permissão para executar essa operação!"
            });
        }
        else{
            const alert = Swal.fire({
                icon: "error",
                title: titulo,
                text: err.response.data
            });
        }
    }
}

async function AlertQuestion(titulo: string, pergunta: string, promise: any){
    var retorno;
    const alert = await Swal.fire({
        title: titulo,
        text: pergunta,
        icon: 'question',
        showDenyButton: true,
        confirmButtonText: 'Sim',
        denyButtonText: 'Não',
        allowOutsideClick: false,
        preConfirm: () => {
            promise();
        }   
    }).then((result) => {
        retorno = result;
    });
    return retorno;
}

async function AlertExcludeQuestion(promise: any, silence: boolean = false){
    var retorno;
    const alert = await Swal.fire({
        title: 'Atenção!',
        text: 'Tem certeza que deseja excluir o registro atual?',
        icon: 'question',
        showDenyButton: true,
        confirmButtonText: 'Sim',
        denyButtonText: 'Não',
        allowOutsideClick: false,
        preConfirm: () => {
            promise();
        }   
    }).then( (result) => {
        if(!silence){
            AlertSimple("Aviso", "Operação Sucesso", "success");
        }
        retorno = result;
    });
    return retorno;
}

export{
    AlertSimpleNotification, AlertSimple, AlertSimpleRes, AlertSimpleErr, AlertQuestion, AlertExcludeQuestion
}