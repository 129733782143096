import { PessoaBase } from "../shared";

export class Pessoa extends PessoaBase implements Shared.IEntity {

    id: number = 0;

    constructor(model?: Pessoa){
        super(model)
        if(!model)
            return;

        this.id = model.id;
    }
}