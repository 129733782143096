export class ContratoEquipe implements Shared.IEntity {

  id: number = 0;
  funcao: string = "";
  funcaoObservacao: string = "";
  planejado: number = 0;

  constructor(model?: ContratoEquipe){

      if(!model)
          return;

      this.id = model.id;
      this.funcao = model.funcao;
      this.funcaoObservacao = model.funcaoObservacao;
      this.planejado = model.planejado;
  }
}